import React, {Component, Fragment} from 'react';
import placeholderImg from '../../assets/images/1280x960.png';
import LightboxWrapper from '../LightboxWrapper/LightboxWrapper';
import Button from '../Button/Button';
import './project-profile.scss';
import {AppContext} from '../../context/AppProvider';
import saveAs from 'file-saver';
import NotificationWrapper from '../_wrappers/NotificationWrapper';
import {eyesColors, findOptionByProperty, footwearSizes, hairColors, teeSizes, trouserSizes} from '../_utils/options';
import {isAdmin} from '../_utils/user';
import {Link} from 'react-router-dom';
import Icon from '../Icon/Icon';
import moment from 'moment';

export default class ProjectProfile extends Component {
    state = {
        isFavorite: this.props.isFavorite || false,
        isSelected: this.props.isSelected || false,
        downloading: false
    };
    idProject = this.props.idProject;

    static defaultProps = {
        onRemove: () => {
        }
    };

    static contextType = AppContext;

    toggleFavorite = event => {
        this.setState({isFavorite: !this.state.isFavorite}, () => {
            if (this.state.isFavorite) {
                this.addFavoriteToProject(this.props.profile.idProfile, this.idProject);
            } else {
                this.removeAsFavoriteFromProject(this.props.profile.idProfile, this.idProject);
            }
        });
    };

    toggleSelected = event => {
        this.setState({isSelected: !this.state.isSelected}, () => {
            if (this.state.isSelected) {
                this.addSelectedToProject(this.props.profile.idProfile, this.idProject);
            } else {
                this.removeAsSelectedToProject(this.props.profile.idProfile, this.idProject);
            }
        });
    };

    removeAsFavoriteFromProject(idProfile, idProject) {
        this.context.services.profile
            .removeFavoriteProject(idProfile, idProject)
            .then(response => {
                if (!response) return;
                this.props.onToggleFavorite();
                this.notification.board.addNotification({
                    title: 'Éxito',
                    content: 'Perfil desasociado como favorito del proyecto',
                    className: 'warning'
                });
            });
    }

    addFavoriteToProject(idProfile, idProject) {
        this.context.services.profile
            .addFavoriteProject(idProfile, idProject)
            .then(response => {
                if (!response) return;
                this.props.onToggleFavorite();
                this.notification.board.addNotification({
                    title: 'Éxito',
                    content: 'Perfil asociado como favorito al proyecto',
                    className: 'success'
                });
            });
    }

    removeAsSelectedToProject(idProfile, idProject) {
        this.context.services.profile
            .removeSelectedProject(idProfile, idProject)
            .then(response => {
                if (!response) return;
                this.props.onToggleSelected();
                this.notification.board.addNotification({
                    title: 'Éxito',
                    content: 'Perfil deseleccionado del proyecto',
                    className: 'warning'
                });
            });
    }

    addSelectedToProject(idProfile, idProject) {
        this.context.services.profile
            .addSelectedProject(idProfile, idProject)
            .then(response => {
                if (!response) return;
                this.props.onToggleSelected();
                this.notification.board.addNotification({
                    title: 'Éxito',
                    content: 'Perfil seleccionado para el proyecto',
                    className: 'success'
                });
            });
    }

    downloadPDF = event => {
        this.setState({downloading: true});
        this.context.services.project
            .downloadProfilePDF(this.idProject, this.props.profile.idProfile)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `ficha_${this.props.profile.idProfile}_${dateString}.pdf`;
                saveAs(response, filename);
                this.setState({downloading: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({downloading: false});
            });
    };

    removeProfile = (idProject, idProfile) => {
        this.context.services.project
            .removeProfile(idProject, idProfile)
            .then(response => {
                if (!response.success) return;
                this.props.onRemove();
            });
    };

    render() {
        const hasImages = this.props.profile.hasOwnProperty('images') && !!this.props.profile.images.length;

        return (
            <Fragment>
                <article className="project-profile columns">
                    <div className="column is-half">
                        <swiper-container>
                            {hasImages
                                ? this.props.profile.images.map((image, index) => (
                                    <swiper-slide key={`${this.props.profile.idProfile}_${index}`}>
                                        <img className="project-profile__image" src={image.path}
                                             alt={image.alt ? image.alt : ''}/>
                                    </swiper-slide>))
                                : <swiper-slide key={`${this.props.profile.idProfile}_fpo`}>
                                    <img className="project-profile__image" src={placeholderImg} alt="Placeholder"/>
                                </swiper-slide>
                            }
                        </swiper-container>
                        {hasImages
                            ? <LightboxWrapper
                                images={this.props.profile.images.map(image => image.path)}
                                onImageLoad={() => {
                                    window.dispatchEvent(new Event('resize'));
                                }}
                            />
                            : null
                        }
                    </div>
                    <div className="column is-half">
                        <h1 className="subtitle is-5 mb-2">
                            {this.props.profile.name || ''} {this.props.profile.surname || ''}
                        </h1>
                        <ul className="columns is-multiline is-gapless pairs">
                            <li className="column is-half pairs__pair">
                                <div className="pairs__key">Altura</div>
                                <div className="pairs__value">
                                    {this.props.profile.height
                                        ? `${this.props.profile.height} cm`
                                        : <Fragment>&mdash;</Fragment>
                                    }
                                </div>
                            </li>
                            <li className="column is-half pairs__pair">
                                <div className="pairs__key">Edad</div>
                                <div className="pairs__value">
                                    {this.props.profile.age
                                        ? `${this.props.profile.age} años`
                                        : <Fragment>&mdash;</Fragment>
                                    }
                                </div>
                            </li>
                            <li className="column is-half pairs__pair">
                                <div className="pairs__key">Color de ojos</div>
                                <div className="pairs__value">
                                    {this.props.profile.eyesColor
                                        ? findOptionByProperty(eyesColors, this.props.profile.eyesColor)
                                        : <Fragment>&mdash;</Fragment>
                                    }
                                </div>
                            </li>
                            <li className="column is-half pairs__pair">
                                <div className="pairs__key">Color de pelo</div>
                                <div className="pairs__value">
                                    {this.props.profile.hairColor
                                        ? findOptionByProperty(hairColors, this.props.profile.hairColor)
                                        : <Fragment>&mdash;</Fragment>
                                    }
                                </div>
                            </li>
                            <li className="column is-half pairs__pair">
                                <div className="pairs__key">Talla de camiseta</div>
                                <div className="pairs__value">
                                    {this.props.profile.teeSize
                                        ? findOptionByProperty(teeSizes, this.props.profile.teeSize, true)
                                        : <Fragment>&mdash;</Fragment>
                                    }
                                </div>
                            </li>
                            <li className="column is-half pairs__pair">
                                <div className="pairs__key">Talla de pantalón</div>
                                <div className="pairs__value">
                                    {this.props.profile.trousersSize
                                        ? findOptionByProperty(trouserSizes, this.props.profile.trousersSize, true)
                                        : <Fragment>&mdash;</Fragment>
                                    }
                                </div>
                            </li>
                            <li className="column is-half pairs__pair">
                                <div className="pairs__key">Número de calzado</div>
                                <div className="pairs__value">
                                    {this.props.profile.footwearSize
                                        ? findOptionByProperty(footwearSizes, this.props.profile.footwearSize, true)
                                        : <Fragment>&mdash;</Fragment>
                                    }
                                </div>
                            </li>
                        </ul>

                        <div className="project-profile__actions buttons mt-2">
                            <Button icon="heart"
                                    extraClasses={`is-small is-link is-outlined ${this.state.isFavorite ? 'project-profile__is-favorite' : ''}`}
                                    text={this.state.isFavorite ? '¡Favorito!' : 'Hacer favorito'}
                                    onClick={this.toggleFavorite}
                            />
                            <Button icon="check-circle"
                                    extraClasses={`is-small is-link is-outlined ${this.state.isSelected ? 'project-profile__is-selected' : ''}`}
                                    text={this.state.isSelected ? '¡Seleccionado!' : 'Seleccionar'}
                                    onClick={this.toggleSelected}
                            />
                            <Button icon={`${this.state.downloading ? 'sync-alt' : 'file-pdf'}`}
                                    spin={this.state.downloading}
                                    disabled={this.state.downloading}
                                    extraClasses="is-small is-link is-outlined"
                                    text={`${this.state.downloading ? 'Descargando…' : 'Descarga ficha en PDF'}`}
                                    onClick={this.downloadPDF}
                            />
                            {isAdmin(this.props.user)
                                ? <Link className="button is-small is-link is-outlined"
                                        to={`/profiles/${this.props.profile.idProfile}`}>
                                    <Icon icon="pencil-alt" text="Editar"/>
                                </Link>
                                : null
                            }
                            {isAdmin(this.props.user)
                                ? <Button icon="user-times"
                                          extraClasses="is-small is-link is-outlined"
                                          text="Quitar del proyecto"
                                          onClick={event => {
                                              if (!window.confirm('¿Seguro que quieres eliminar este perfil del proyecto?')) return;
                                              this.removeProfile(this.idProject, this.props.profile.idProfile);
                                          }}
                                />
                                : null
                            }
                        </div>
                    </div>
                </article>
                <NotificationWrapper ref={ref => this.notification = ref}/>
            </Fragment>
        );
    }
}
