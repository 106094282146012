import React, { Component } from 'react';
import Select from '../Form/Select';
import Input from '../Form/Input';
import Checkbox from '../Form/Checkbox';
import {
    ethnicOrigins,
    eyesColors,
    footwearSizes,
    genders,
    hairColors,
    nationalities,
    spanishProvinces,
    spokenLanguages,
    teeSizes,
    trouserSizes
} from '../_utils/options';
import Slider from '../Form/Slider';
import FieldWrapper from '../Form/FieldWrapper';
import Button from '../Button/Button';

export default class ProfileFilters extends Component {
    state = {
        formData: this.getStoredFilters() || this.setInitiaFilters()
    };

    static defaultProps = {
        onFilter: () => {}
    };

    handleChange = field => {
        const formData = Object.assign(this.state.formData, {[field.name]: field.value});
        this.setState({formData});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.storeFilters(this.state.formData);
        this.props.onFilter(this.state.formData);
    };

    storeFilters(filters) {
        localStorage.setItem('profileFilters', JSON.stringify(filters));
    }

    getStoredFilters() {
        return JSON.parse(localStorage.getItem('profileFilters'));
    }

    resetFilters = event => {
        event.preventDefault();

        this.setState({formData: this.setInitiaFilters()}, () => {
            localStorage.removeItem('profileFilters');
            this.props.onFilter(this.state.formData);
        });
    };

    setInitiaFilters() {
        return {
            q: '',
            provinceIds: 0,
            teeSize: null,
            trousersSize: null,
            footwearSize: null,
            hairColor: null,
            eyesColor: null,
            spokenLanguages: null,
            ethnicOrigin: null,
            nationality: null,
            age: [0, 100],
            weight: [0, 150],
            height: [0, 230],
            gender: 0,
            actor: false,
            model: false,
            tattoosOrScars: false,
            drivingLicense: false,
            sport: false,
            acceptFiguration: false,
            hideInactive: true
        };
    }

    render() {
        return (
            <form className="card" onSubmit={this.handleSubmit}>
                <header className="card-header">
                    <p className="card-header-title">Filtros</p>
                </header>
                <div className="card-content p-2">
                    <FieldWrapper label="Búsqueda textual">
                        <Input placeholder="Nombre, email, DNI, etc…"
                               name="q"
                               value={this.state.formData.q}
                               iconLeft="search"
                               onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Provincias">
                        <Select placeholder="Provincias"
                                name="provinceIds"
                                value={this.state.formData.provinceIds}
                                multiple
                                options={spanishProvinces}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Talla de camiseta">
                        <Select placeholder="Talla de camiseta"
                                name="teeSize"
                                value={this.state.formData.teeSize}
                                options={teeSizes}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Talla de pantalones">
                        <Select placeholder="Talla de pantalones"
                                name="trousersSize"
                                value={this.state.formData.trousersSize}
                                options={trouserSizes}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Talla de calzado">
                        <Select placeholder="Talla de calzado"
                                name="footwearSize"
                                value={this.state.formData.footwearSize}
                                options={footwearSizes}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Color de pelo">
                        <Select placeholder="Color de pelo"
                                name="hairColor"
                                value={this.state.formData.hairColor}
                                options={hairColors}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Color de ojos">
                        <Select placeholder="Color de ojos"
                                name="eyesColor"
                                value={this.state.formData.eyesColor}
                                options={eyesColors}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Idiomas">
                        <Select placeholder="Idiomas"
                                name="spokenLanguages"
                                value={this.state.formData.spokenLanguages}
                                multiple
                                options={spokenLanguages}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Etnia">
                        <Select placeholder="Etnia"
                                name="ethnicOrigin"
                                value={this.state.formData.ethnicOrigin}
                                options={ethnicOrigins}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Nacionalidad">
                        <Select placeholder="Nacionalidad"
                                name="nationality"
                                value={this.state.formData.nationality}
                                options={nationalities}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Género">
                        <Select placeholder="Género"
                                name="gender"
                                value={this.state.formData.gender}
                                options={genders}
                                onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper label="Edad">
                        <Slider name="age"
                                range={this.state.formData.age}
                                onChange={this.handleChange} />
                    </FieldWrapper>
                    <FieldWrapper label="Peso">
                        <Slider name="weight"
                                endValue={120}
                                range={this.state.formData.weight}
                                onChange={this.handleChange} />
                    </FieldWrapper>
                    <FieldWrapper label="Altura">
                        <Slider name="height"
                                endValue={230}
                                range={this.state.formData.height}
                                onChange={this.handleChange} />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Checkbox name="actor"
                                  checked={this.state.formData.actor}
                                  label="Actor profesional"
                                  onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Checkbox name="model"
                                  checked={this.state.formData.model}
                                  label="Modelo profesional"
                                  onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Checkbox name="tattoosOrScars"
                                  checked={this.state.formData.tattoosOrScars}
                                  label="Con tatuajes o cicatrices"
                                  onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Checkbox name="drivingLicense"
                                  checked={this.state.formData.drivingLicense}
                                  label="Carnet de conducir"
                                  onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Checkbox name="sport"
                                  checked={this.state.formData.sport}
                                  label="Practica deporte"
                                  onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Checkbox name="acceptFiguration"
                                  checked={this.state.formData.acceptFiguration}
                                  label="¿Acepta trabajos de figuración?"
                                  onChange={this.handleChange}
                        />
                    </FieldWrapper>
                    <hr/>
                    <FieldWrapper>
                        <Checkbox name="hideInactive"
                                  checked={this.state.formData.hideInactive}
                                  label="Ocultar inactivos"
                                  onChange={this.handleChange}
                        />
                    </FieldWrapper>
                </div>
                <div className="card-footer">
                    <div className="card-footer-item">
                        <Button extraClasses="is-fullwidth"
                                text="Quitar filtros"
                                icon="undo-alt"
                                type="reset"
                                onClick={this.resetFilters}
                        />
                    </div>
                    <div className="card-footer-item">
                        <Button extraClasses="is-info is-fullwidth"
                                text="Filtrar"
                                icon="filter"
                                type="submit"
                        />
                    </div>
                </div>
            </form>
        );
    }
}
