import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import placeholderImg from '../../assets/images/1280x960.png';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import ProjectSelector from '../Project/ProjectSelector';
import './profile-card.scss';
import Icon from '../Icon/Icon';
import Checkbox from "../Form/Checkbox";

export default class ProfileCard extends Component {
    state = {
        modalAddToProjectOpen: false
    };

    checkProfile = () => {
        if (this.props.checkProfile) {
            this.props.checkProfile(this.props.profile.idProfile, !this.props.profile.checked);
        }
    }

    openAddToProjectModal = event => {
        event.preventDefault();
        this.setState({modalAddToProjectOpen: true});
    };

    closeAddToProjectModal = event => {
        event.preventDefault();
        this.setState({modalAddToProjectOpen: false});
    };

    render() {
        return (
            <Fragment>
                <article className={"card profile-card " +  (this.props.profile.checked ? 'profile-card--checked' : '')} >
                    <Checkbox
                        className="checkbox"
                        name="checkedProfile"
                        checked={this.props.profile.checked ?? false}
                        onChange={this.checkProfile}
                    />
                    <div className="card-image">
                        <swiper-container>
                            {this.props.profile.hasOwnProperty('images') && this.props.profile.images.length
                                ? this.props.profile.images.map((image, index) => (
                                    <swiper-slide key={`${this.props.profile.idProfile}_${index}`}>
                                        <img className="profile-card__image" src={image.path}
                                             alt={image.alt ? image.alt : ''}/>
                                    </swiper-slide>))
                                : <swiper-slide key={`${this.props.profile.idProfile}_fpo`}>
                                    <img className="profile-card__image" src={placeholderImg} alt="Placeholder"/>
                                </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                    <div className="card-content">
                        <div className="columns mb-0">
                            <div className="column">

                                <h1 className="profile-card__name">{this.props.profile.name} {this.props.profile.surname}</h1>
                            </div>
                            <div className="column is-narrow">
                                <div className="profile-age">
                                    <div className="profile-age__number">
                                        {this.props.profile.age || 0}
                                    </div>
                                    <div className="profile-age__units">
                                        años
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="tags">
                                {this.props.profile.weight ?
                                    <span className="tag is-primary">{this.props.profile.weight} kg</span> : null}
                                {this.props.profile.height ?
                                    <span className="tag is-primary">{this.props.profile.height} cm</span> : null}
                                {this.props.profile.actor ? <span className="tag is-primary">actor</span> : null}
                                {this.props.profile.model ? <span className="tag is-primary">modelo</span> : null}
                                {this.props.profile.tattoosOrScars ?
                                    <span className="tag is-primary">tattoos o cicatrices</span> : null}
                                {this.props.profile.sport ? <span className="tag is-primary">deportista</span> : null}
                            </div>
                            <div className="tags">
                                {!this.props.profile.active ? <span className="tag is-danger">inactivo</span> : null}
                            </div>
                        </div>
                    </div>
                    <footer className="card-footer">
                        <button className="button card-footer-item"
                                onClick={this.openAddToProjectModal}>
                            <Icon icon="random" fw/> Asociar…
                        </button>
                        <Link to={`/profiles/${this.props.profile.idProfile}`}
                              className="card-footer-item">
                            <Icon icon="arrow-right" fw/> Ficha
                        </Link>
                    </footer>
                </article>

                <ModalWrapper title="Asociar a proyecto"
                              open={this.state.modalAddToProjectOpen}
                              onClose={this.closeAddToProjectModal}>
                    <ProjectSelector idUser={this.props.profile.user.idUser}/>
                </ModalWrapper>
            </Fragment>
        );
    }
}