import React, {Component, Fragment} from 'react';
import {AppContext} from '../../../context/AppProvider';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import {withLayout} from '../../HOC/WithLayout';
import Hero from '../../Hero/Hero';
import placeholderImg from '../../../assets/images/1280x960.png';
import {FileDropper} from '../../FileDropper/FileDropper';
import ProfileForm from '../../ProfileForm/ProfileForm';
import NotificationWrapper from '../../_wrappers/NotificationWrapper';
import Button from '../../Button/Button';
import ProjectSelector from '../../Project/ProjectSelector';
import Icon from '../../Icon/Icon';
import LoadMaskWrapper from '../../_wrappers/LoadMaskWrapper';
import {ProfileDataAdapter} from './ProfileDataAdapter';
import AsideBlock from '../../AsideBlock/AsideBlock';
import {isAdmin} from '../../_utils/user';
import ProfileImageManager from '../../ProfileImageManager/ProfileImageManager';
import {Link} from 'react-router-dom';
import saveAs from 'file-saver';
import moment from 'moment';
import './profile-page.scss';
import {withRouter} from "../LoginPage/withRouter";
import ModalDeleteProfileWrapper from "../../ModalWrapper/ModalDeleteProfileWrapper";
import FieldWrapper from "../../Form/FieldWrapper";
import Checkbox from "../../Form/Checkbox";

class ProfilePage extends Component {
    state = {
        profile: {},
        modalAddToProjectOpen: false,
        modalDeleteProfileOpen: false,
        loading: true,
        sending: false,
        downloading: false,
        downloadingFullPDF: false,
        userCanEdit: false,
        sendEmailToCandidate: false,
    };
    idProfile = this.props.params.idProfile;

    // Número máximo de imágenes que el sistema debe permitir subir a un perfil
    maxImages = 10;

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.swiper = React.createRef();
    }

    componentDidMount() {
        this.findProfile(this.idProfile);
    }

    openAddToProjectModal = () => {
        this.setState({modalAddToProjectOpen: true});
    };

    closeAddToProjectModal = () => {
        this.setState({modalAddToProjectOpen: false});
    };

    findProfile(idProfile) {
        this.setState({loading: true});
        this.context.services.profile
            .findById(idProfile)
            .then(profile => {
                const assignedInActiveProjects = profile.user.projects.some(project => project.active);

                this.setState({
                    profile,
                    userCanEdit: !assignedInActiveProjects || isAdmin(this.props.user)
                }, () => {
                    if (!profile.images.length) {
                        this.setState({loading: false});
                        return;
                    }
                    if (this.swiper.update) this.swiper.update();
                    if (this.swiper.rebuild) this.swiper.rebuild();
                    this.setState({loading: false});
                });
            })
            .catch(reason => {
                this.props.navigate({pathname: '/unauthorized', state: reason.message});
            });
    }

    updateProfile = (idProfile, formData) => {
        this.setState({sending: true});

        this.context.services.profile
            .update(idProfile, ProfileDataAdapter.fromUpdateForm(formData))
            .then(() => {
                this.notification.board.addNotification({
                    title: '¡Todo bien!',
                    content: 'Los datos del candidato se han actualizado correctamente',
                    className: 'success'
                });
                this.findProfile(idProfile);
                this.setState({sending: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error actualizando perfil',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({sending: false});
            });
    };

    uploadImage = (image, rejectedFiles) => {
        if (rejectedFiles.length) return;

        this.context.services.image
            .create(this.idProfile, image[0])
            .then(response => {
                if (!response.success) return;
                this.findProfile(this.idProfile);
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error subiendo imagen',
                    content: reason.message,
                    className: 'danger'
                });
            });
    };

    toggleLock = (event, idProfile, active) => {
        event.preventDefault();

        this.context.services.profile
            .updateActive(idProfile, active)
            .then(response => {
                if (!response.success) return;

                if (active) {
                    this.notification.board.addNotification({
                        title: 'Perfil Actualizado',
                        content: 'El perfil se ha activado',
                        className: 'success'
                    });
                } else {
                    this.notification.board.addNotification({
                        title: 'Perfil Actualizado',
                        content: 'El perfil se ha desactivado',
                        className: 'warning'
                    });
                }

                this.findProfile(idProfile);
            });
    };

    toggleEnable = (event, idProfile, idUser, enable) => {
        event.preventDefault();

        this.context.services.user
            .updateEnable(idUser, enable)
            .then(response => {
                if (!response.success) return;

                if (enable) {
                    this.notification.board.addNotification({
                        title: 'Usuario Actualizado',
                        content: 'Se permite el acceso a la aplicación a este usuario',
                        className: 'success'
                    });
                } else {
                    this.notification.board.addNotification({
                        title: 'Usuario Actualizado',
                        content: 'Se prohibe el acceso a la aplicación a este usuario',
                        className: 'warning'
                    });
                }

                this.findProfile(idProfile);
            });
    };

    downloadPDF = () => {
        this.setState({downloading: true});
        this.context.services.profile
            .downloadPDF(this.idProfile)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `ficha_${this.idProfile}_${dateString}.pdf`;
                saveAs(response, filename);
                this.setState({downloading: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({downloading: false});
            });
    };

    downloadDetailPDF = () => {
        this.setState({downloadingFullPDF: true});
        this.context.services.profile
            .downloadDetailPDF(this.idProfile)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `ficha-completa_${this.idProfile}_${dateString}.pdf`;
                saveAs(response, filename);
                this.setState({downloadingFullPDF: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({downloadingFullPDF: false});
            });
    };

    handleRemoveImage = idProfile => {
        this.findProfile(idProfile);
        this.notification.board.addNotification({
            title: '¡Imagen eliminada!',
            content: 'La imagen se ha eliminado correctamente',
            className: 'warning'
        });
    };

    handleSortEnd = idProfile => {
        this.findProfile(idProfile);
        this.notification.board.addNotification({
            title: 'Imágenes reordenadas',
            content: 'Las imágenes se han reordenado',
            className: 'success'
        });
    };

    removeProject = (idProject, idProfile) => {
        this.context.services.project
            .removeProfile(idProject, idProfile)
            .then(response => {
                if (response.success) {
                    this.findProfile(idProfile);
                    this.notification.board.addNotification({
                        title: 'Éxito',
                        content: 'Perfil eliminado del proyecto',
                        className: 'warning'
                    });
                }
            });
    };

    openDeleteProfileModal = () => {
        this.setState({modalDeleteProfileOpen: true});
    };

    closeDeleteProfileModal = () => {
        this.setState({modalDeleteProfileOpen: false});
    };

    handleDeleteProfile = (idProfile) => {
        this.context.services.profile
            .delete(idProfile, this.state.sendEmailToCandidate)
            .then(response => {
                if (response.success) {
                    this.props.navigate({pathname: '/profiles'});

                    this.notification.board.addNotification({
                        title: 'Éxito',
                        content: 'Perfil eliminado del sistema',
                        className: 'warning'
                    });
                }
            });
    };

    render() {
        const hasImages = this.state.profile.hasOwnProperty('images') && !!this.state.profile.images.length;

        return (
            <div className="profile-page">
                <Hero title="Ficha de perfil" subtitle={`${this.state.profile.name} ${this.state.profile.surname}`}/>
                <section className="section">
                    <div className="container">
                        {isAdmin(this.props.user)
                            ? <Breadcrumb model={[
                                {name: 'Listado de perfiles', link: '/profiles/'},
                                {
                                    name: `${this.state.profile.name} ${this.state.profile.surname}`,
                                    link: './',
                                    active: true
                                }]}
                            />
                            : null
                        }
                        {!this.state.userCanEdit
                            ? <div className="columns">
                                <div className="column">
                                    <article className="message is-danger">
                                        <div className="message-body">
                                            <p><Icon icon="exclamation-triangle"/> El motivo por el que no puedes
                                                cambiar tus fotos (y otros datos) es porque estás en un proceso de
                                                selección. Si tienes dudas <a
                                                    href="mailto:hola@tropicalcasting.com">contacta con nosotras</a>…
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            : null
                        }
                        <div className="columns">
                            <div className="column is-one-third">
                                {hasImages ?
                                <AsideBlock title="Imágenes del perfil">
                                    <swiper-container init={hasImages} ref={this.swiper}>
                                        {hasImages
                                            ? this.state.profile.images.map((image, index) => (
                                                <swiper-slide key={`${this.state.profile.idProfile}_${index}`}>
                                                    <img className="profile-page__image" src={image.path}
                                                         alt={image.alt ? image.alt : ''}/>
                                                </swiper-slide>))
                                            : <swiper-slide>
                                                <img className="profile-page__image" src={placeholderImg}
                                                     alt="Placeholder"/>
                                            </swiper-slide>
                                        }
                                    </swiper-container>
                                </AsideBlock>
                                    : null }
                                {hasImages && this.state.userCanEdit
                                    ? <AsideBlock title="Gestión de imágenes">
                                        <ProfileImageManager user={this.props.user}
                                                             images={this.state.profile.images}
                                                             onRemoveImage={() => this.handleRemoveImage(this.idProfile)}
                                                             onSortEnd={() => this.handleSortEnd(this.idProfile)}
                                        />
                                    </AsideBlock>
                                    : null
                                }
                                {this.state.userCanEdit
                                    ? <AsideBlock title="Subida de imágenes">
                                        {hasImages && this.state.profile.images.length >= this.maxImages
                                            ? <article className="message is-warning">
                                                <div className="message-body">
                                                    <p><Icon icon="thumbs-up"/> Has alcanzado el límite
                                                        de {this.maxImages} imágenes en tu perfil. No es posible subir
                                                        nuevas imágenes por ahora…</p>
                                                </div>
                                            </article>
                                            : <FileDropper onDrop={this.uploadImage}/>
                                        }
                                    </AsideBlock>
                                    : null
                                }
                                {isAdmin(this.props.user)
                                    ? <AsideBlock title="Acciones globales">
                                        <div className="buttons">
                                            <Button icon="random"
                                                    extraClasses="is-info"
                                                    text="Asociar a proyecto"
                                                    onClick={this.openAddToProjectModal}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <p className="mb-1 has-text-grey">
                                                Perfil {this.state.profile.active ? 'activo' : 'inactivo'}
                                            </p>
                                            <Button icon="unlock"
                                                    extraClasses={this.state.profile.active ? 'is-warning' : 'is-success'}
                                                    text={this.state.profile.active ? 'Desactivar' : 'Activar'}
                                                    onClick={event => this.toggleLock(event, this.idProfile, !this.state.profile.active)}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.state.profile.user
                                                ? <Fragment>
                                                    <p className="mb-1 has-text-grey">
                                                        Acceso {this.state.profile.user.enabled ? 'permitido' : 'bloqueado'}
                                                    </p>
                                                    <Button icon="ban"
                                                            extraClasses={this.state.profile.user.enabled ? 'is-warning' : 'is-success'}
                                                            text={this.state.profile.user.enabled ? 'Bloquear acceso' : 'Permitir acceso'}
                                                            onClick={event => this.toggleEnable(event, this.idProfile, this.state.profile.user.idUser, !this.state.profile.user.enabled)}
                                                    />
                                                </Fragment>
                                                : null
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <p className="mb-1 has-text-grey">Eliminar perfil</p>
                                            <Button icon="trash"
                                                    extraClasses="is-danger"
                                                    text="Eliminar"
                                                    onClick={this.openDeleteProfileModal}
                                                    disabled={this.state.profile.user ? this.state.profile.user.projects.length : ''}
                                            />
                                        </div>
                                        <ModalWrapper title="Asociar a proyecto"
                                                      open={this.state.modalAddToProjectOpen}
                                                      onClose={this.closeAddToProjectModal}>
                                            {this.state.profile && this.state.profile.hasOwnProperty('user')
                                                ? <ProjectSelector idUser={this.state.profile.user.idUser}
                                                                   onUpdateUser={() => this.findProfile(this.idProfile)}
                                                />
                                                : null
                                            }
                                        </ModalWrapper>

                                        <ModalDeleteProfileWrapper title="Eliminar perfil"
                                                                   open={this.state.modalDeleteProfileOpen}
                                                                   onClose={this.closeDeleteProfileModal}
                                                                   onDeleteProfile={() => this.handleDeleteProfile(this.idProfile)}>
                                            <p className="mb-2">
                                                ¿Está seguro de que desea borrar este perfil junto con sus imágenes?
                                            </p>
                                            <FieldWrapper>
                                                <Checkbox name="sendEmail"
                                                          checked={this.state.sendEmailToCandidate}
                                                          label="Enviar correo electónico al cadidato"
                                                          onChange={(event) => {
                                                              this.setState({sendEmailToCandidate: event.value});
                                                          }}
                                                />
                                            </FieldWrapper>

                                            {/*<input*/}
                                            {/*    type="checkbox"*/}
                                            {/*    id="delete-checkbox"*/}
                                            {/*    checked={this.state.sendEmailToCandidate}*/}
                                            {/*    onChange={event => {*/}
                                            {/*        setOptIn(event.target.checked);*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            {/*<checkbox name="sendEmail"*/}
                                            {/*          checked={this.state.sendEmailToCandidate}*/}
                                            {/*          label="Enviar correo electónico al cadidato"*/}
                                            {/*          onClick={(event) => {*/}
                                            {/*              this.setState({sendEmailToCandidate: event.target.checked})*/}
                                            {/*          }}*/}
                                            {/*/>*/}


                                        </ModalDeleteProfileWrapper>
                                    </AsideBlock>
                                    : null
                                }
                                {isAdmin(this.props.user) &&
                                    <Fragment>
                                        <AsideBlock title="Ficha pública PDF">
                                            <Button icon={`${this.state.downloading ? 'sync-alt' : 'file-pdf'}`}
                                                    spin={this.state.downloading}
                                                    disabled={this.state.downloading}
                                                    extraClasses="is-info"
                                                    text={`${this.state.downloading ? 'Descargando…' : 'Descargar PDF Público'}`}
                                                    onClick={this.downloadPDF}
                                            />
                                        </AsideBlock>
                                        <AsideBlock title="Ficha PDF completa">
                                            <Button icon={`${this.state.downloadingFullPDF ? 'sync-alt' : 'file-pdf'}`}
                                                    spin={this.state.downloadingFullPDF}
                                                    disabled={this.state.downloadingFullPDF}
                                                    extraClasses="is-info"
                                                    text={`${this.state.downloadingFullPDF ? 'Descargando…' : 'Descargar PDF Completo'}`}
                                                    onClick={this.downloadDetailPDF}
                                            />
                                        </AsideBlock>
                                    </Fragment>
                                }
                                {isAdmin(this.props.user)
                                    ? <Fragment>
                                        <AsideBlock title="Asignado en proyectos">
                                            <table
                                                className="table is-bordered is-hoverable is-fullwidth is-narrow table--align-middle table--mini-table">
                                                <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th className="has-text-centered">Activo</th>
                                                    <th style={{textAlign: 'center'}}>Ficha</th>
                                                    <th style={{textAlign: 'center'}}>Quitar</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.profile.user && this.state.profile.user.projects && this.state.profile.user.projects.map(project => (
                                                    <tr key={project.idProject}>
                                                        <td>
                                                            {project.name}
                                                        </td>
                                                        <td className="has-text-centered">
                                                            {project.active ? 'sí' : 'no'}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Link className="show-tooltip button is-white"
                                                                  data-tooltip="Ver ficha"
                                                                  to={`/projects/${project.idProject}`}>
                                                                <Icon icon="arrow-circle-right"/>
                                                            </Link>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Button icon="user-times"
                                                                    extraClasses="is-small is-outlined is-danger"
                                                                    data-tooltip="Quitar del proyecto"
                                                                    onClick={event => {
                                                                        if (!window.confirm('¿Seguro que quieres eliminar este perfil del proyecto?')) return;
                                                                        this.removeProject(project.idProject, this.idProfile);
                                                                    }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td className="has-text-grey" colSpan={1000}>
                                                        {this.state.profile.user && this.state.profile.user.projects && this.state.profile.user.projects.length
                                                            ? `Seleccionado en ${this.state.profile.user.projects.length} proyectos`
                                                            : 'No se encuentran resultados…'
                                                        }
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </AsideBlock>
                                    </Fragment>
                                    : null
                                }
                            </div>
                            <div className="column is-two-thirds mb-3">
                                {this.state.profile.hasOwnProperty('idProfile')
                                    ? <ProfileForm userCanEdit={this.state.userCanEdit}
                                                   user={this.props.user}
                                                   profile={this.state.profile}
                                                   sending={this.state.sending}
                                                   onUpdate={formData => this.updateProfile(this.idProfile, formData)}
                                    />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <LoadMaskWrapper visible={this.state.loading}
                                 backgroundLayerStyle={{position: 'fixed'}}>
                    <h3>Cargando perfil…</h3>
                </LoadMaskWrapper>
                <NotificationWrapper ref={ref => this.notification = ref}/>
            </div>
        );
    }
}

export default withRouter(withLayout(ProfilePage));
