import BaseService from './BaseService';

export default class ProjectService extends BaseService {

    create(name) {
        const endpoint = `projects/create`;
        const data = new URLSearchParams();
        data.append('name', name);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'POST',
                    body: data
                },
            null,
            {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    update(idProject, name, active = true) {
        const endpoint = `projects/${idProject}`;
        const data = new URLSearchParams();
        data.append('name', name);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                undefined,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    search(page = 1, q = null, active = true) {
        const endpoint = 'projects/search';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, q, active})
            .then(response => response);
    }

    findAll(page = 1, active = true) {
        const endpoint = 'projects';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, active})
            .then(response => response);
    }

    findList(list = true, active = true) {
        const endpoint = 'projects';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {list, active})
            .then(response => response);
    }

    findById(idProfile) {
        const endpoint = `projects/${idProfile}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => response);
    }

    findByUser(idUser, active = true) {
        const endpoint = `projects/user/${idUser}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {active})
            .then(response => response);
    }

    addUser(idProject, idUser) {
        const endpoint = `projects/${idProject}/add-user/${idUser}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeUser(idProject, idUser) {
        const endpoint = `projects/${idProject}/remove-user/${idUser}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    addProfile(idProject, idProfile) {
        const endpoint = `projects/${idProject}/add-profile/${idProfile}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    addProfiles(idProject, idsProfile = []) {
        const endpoint = `projects/${idProject}/add-profiles`;
        const data = new URLSearchParams();
        idsProfile.forEach((idProfile, index) => {
            data.append(`idsProfile[${index}]`, idProfile);
        });
        console.log(data);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'POST',
                    body: data
                },
                undefined,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    removeProfile(idProject, idProfile) {
        const endpoint = `projects/${idProject}/remove-profile/${idProfile}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeAllProfiles(idProject, idsProfiles) {
        const endpoint = `projects/${idProject}/remove-profiles/${idsProfiles.join(',')}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    downloadPDF(idProject) {
        const endpoint = `projects/${idProject}/download-pdf`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, undefined, {'Accept': 'application/pdf', 'Content-Type': 'application/pdf'}, 'blob')
            .then(response => response);
    }

    downloadSelectedPDF(idProject) {
        const endpoint = `projects/${idProject}/download-pdf/selected`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, undefined, {'Accept': 'application/pdf', 'Content-Type': 'application/pdf'}, 'blob')
            .then(response => response);
    }

    downloadSelectedPDFListing(idProject) {
        const endpoint = `projects/${idProject}/download-pdf/listing`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, null, {'Accept': 'application/pdf', 'Content-Type': 'application/pdf'}, 'blob')
            .then(response => response);
    }

    downloadFavoritePDF(idProject) {
        const endpoint = `projects/${idProject}/download-pdf/favorites`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, undefined, {'Accept': 'application/pdf', 'Content-Type': 'application/pdf'}, 'blob')
            .then(response => response);
    }

    downloadProfilePDF(idProject, idProfile) {
        const endpoint = `projects/${idProject}/profile/${idProfile}/download-pdf`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, undefined, {'Accept': 'application/pdf', 'Content-Type': 'application/pdf'}, 'blob')
            .then(response => response);
    }

    addFavoriteProfile(idProject, idProfile) {
        const endpoint = `projects/${idProject}/favorite/${idProfile}/add`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeFavoriteProfile(idProject, idProfile) {
        const endpoint = `projects/${idProject}/favorite/${idProfile}/remove`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    addSelectedProfile(idProject, idProfile) {
        const endpoint = `projects/${idProject}/selected/${idProfile}/add`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeSelectedProfile(idProject, idProfile) {
        const endpoint = `projects/${idProject}/selected/${idProfile}/remove`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    toggleActive(idProject, active) {
        const endpoint = `projects/${idProject}/active`;
        const data = new URLSearchParams();
        data.append('active', active);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                undefined,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    share(idProject, expirationDate) {
        const endpoint = `projects/${idProject}/share`;
        const data = new URLSearchParams();
        data.append('expirationShareDate', expirationDate);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'POST',
                    body: data
                },
                undefined,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }
}
